import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { useEffect } from 'react';

// import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// import { BrowserRouter } from 'react-router-dom' ;
import { firebaseDb, firebaseAuth } from './firebase.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
