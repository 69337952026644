import React, { Component, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faAngleRight, faCog, faPencil, faEyeSlash, faEye, faSeedling, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { firebaseDb, firebaseAuth } from './firebase.js';
import 'font-awesome/css/font-awesome.min.css';
import { withRouter, useHistory, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import Lottie from 'lottie-react';
import greenCheck from './lotties/greencheck.json';
import bankcoins from './img/bankcoins.PNG';
import dollarbills from './img/dollarbills.png';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './index.css'

import Navigation from './Navigation';
import FreeTrialAlert from './components/FreeTrialAlert';
import ConfirmScreen from './components/ConfirmScreen.js';
import { reformatDate } from "./util/Util";


// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";

// library.add(faCoffee);

const dbURL = 'https://vermillionapp.fly.dev/';
// const dbURL = 'https://shielded-bastion-58122.herokuapp.com/';

function Card({ name, accountid, setCurrEditAccount, setCurrEditAccountId }) {
	return (
		<>
			<div className="active desktopAccountSingleContainer hoverPointer"
				id="activeAccount"
				key={'cont2-' + accountid}
				onClick={function (evt) {
					setCurrEditAccount(name);
					setCurrEditAccountId(accountid);
				}}>
				<div className="row">
					{/* <div className="text-left accountSingleBalance">{toCurrency(running_balance)}</div> */}
					<div className="text-left accountSingleName">{name}</div>
					<div className="text-left accountSingleStars">**** **** ****</div>
				</div>
			</div>

		</>
	)
}

export default function Accounts(props) {
	let navigate = useNavigate();
	var newDate = new Date();

	const [userID, setUserID] = useState('');
	const [email, setEmail] = useState('');
	const [accounts, setAccounts] = useState([]);
	const [currEditAccount, setCurrEditAccount] = useState('');
	const [currEditAccountId, setCurrEditAccountId] = useState('');
	const [newAccName, setNewAccName] = useState('');
	const [newAccType, setNewAccType] = useState('');
	const [newAccDescription, setNewAccDescription] = useState('');
	const [idToken, setIdToken] = useState('');
	const [confirmed, setConfirmed] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showHide, setShowHide] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [hidden, setHidden] = useState(false);
	let [days_left, setDaysLeft] = useState(600);
	const [showNeedAccount, setShowNeedAccount] = useState(false);
	const [isLoading, setIsLoading] = useState(true);


	const api_get_accounts = (authToken) => {
		const data = { token: authToken, color2: 'red' };
		const requestOptions = {
			method: 'GET'
		};
		// Where we're fetching data from
		const baseURL = dbURL + 'getaccounts?';
		const reqURL = baseURL + 'token=' + data.token;
		// console.log(reqURL);
		return fetch(reqURL, requestOptions)
			// We get the API response and receive data in JSON format
			.then((response) => response.json())
			.then((data) => {
				const newData = data.results.rows
				// console.log("new data ",data.results.rows);
				return (newData);
			})
			.catch((error) => console.error(error));
	}


	const api_add_account = (newName, newDescription, newBalance, newType) => {
		const data = {
			token: idToken,
			name: newName,
			description: newDescription,
			initial_balance: toNumber(newBalance),
			acc_type: newType
		};
		const requestOptions = {
			method: 'POST',
			// headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({})
		};

		// console.log('Adding before api post...');
		// Where we're fetching data from
		const baseURL = dbURL + 'addaccount?';
		const reqURL = baseURL
			+ 'token=' + data.token
			+ '&name=' + encodeURIComponent(data.name)
			+ '&description=' + encodeURIComponent(data.description)
			+ '&initial_balance=' + data.initial_balance
			+ '&acc_type=' + encodeURIComponent(data.acc_type);
		console.log(reqURL);
		return fetch(reqURL, requestOptions)
			// We get the API response and receive data in JSON format
			.then((response) => response.json())
			// .then((data) => console.log("Adding after api post.." + data))
			.catch((error) => console.error(error));
	}



	const addNewAccount = () => {
		// Positive initial balance should be categoryid = 0, available to budget this month.
		// Negative initial balance should be categroyid = -5, 
		// contributing to the running balance but NOT the "available to budget".
		// We are NOT going to add a category for this debt. Instead it will be listed as 
		// "in-budget debt" when we make debts

		const newName = document.getElementById('accountName').value;
		const newDescription = document.getElementById('accountDescription').value;
		const newBalance = document.getElementById('accountBalance').value;
		const newType = document.getElementById('accountType').value;
		api_add_account(newName, newDescription, newBalance, newType);
		setTimeout(() => {
			// console.log("Toggling the other value...");
			setConfirmed(!confirmed);
		}, 550);
		return (1);

	}


	const api_update_account = (editName, editType, editDescription) => {
		// console.log("About to make the API reuqest...POST");
		const data = {
			token: idToken,
			accountid: currEditAccountId,
			name: editName,
			description: editDescription,
			type: editType
		};
		const requestOptions = {
			method: 'POST',
			// headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({})
		};

		// console.log('Adding before api post...');
		// Where we're fetching data from
		const baseURL = dbURL + 'updateaccount?';
		const reqURL = baseURL
			+ 'token=' + data.token
			+ '&accountid=' + data.accountid
			+ '&name=' + encodeURIComponent(data.name)
			+ '&description=' + encodeURIComponent(data.description)
			+ '&type=' + data.type;
		console.log(reqURL);
		// return(1);
		return fetch(reqURL, requestOptions)
			// We get the API response and receive data in JSON format
			.then((response) => response.json())
			// .then((data) => console.log("Adding after api post.." + data))
			.catch((error) => console.error(error));
	}

	const attemptUpdateAcc = (editName, editType, editDescription) => {
		// const newName = document.getElementById('newAccDescriptionInput').value;
		// console.log(newName);
		api_update_account(editName, editType, editDescription);

		// Update the list of accounts
		// Find the index of the object with the matching 'accountid'
		const indexToUpdate = accounts.findIndex(d => d.accountid === currEditAccountId);

		// Check if the 'accountid' exists in the list
		// Update the 'name' property for the found object
		accounts[indexToUpdate]['name'] = editName;
		accounts[indexToUpdate]['type'] = editType;
		accounts[indexToUpdate]['description'] = editDescription;

		setTimeout(() => {
			// console.log("Closing modal...");
			setCurrEditAccountId('');
			// console.log("Closing modal...");
		}, 250);

	}


	function AddAccountButton() {
		const [show, setShow] = useState(false);
		const [initialBalance, setInitialBalance] = useState(null);
		const [initialName, setInitialName] = useState(null);

		const handleClose = () => {
			setShow(false);
			setInitialBalance(null);
		}
		const handleShow = () => setShow(true);

		return (
			<>
				<div className='button button-darkGreen'
					onClick={handleShow}>
					+ Add Account
				</div>


				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Add New Account</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-3">
							<label className="col-form-label">Name:</label>
							<input type="text" className="form-control"
								id="accountName"
								onBlur={e => { setInitialName(e.target.value); }}
							// onBlur={e => {
							// updateAmt(e.target.value);}}
							/>
						</div>
						<div className="mb-3">
							<label className="col-form-label">Description:</label>
							<input type="text" className="form-control" id="accountDescription" />
						</div>
						<div className="mb-3">
							<label className="col-form-label">Account Type:</label>
							<select name="type" id="accountType"
								className="form-control" >
								<option value="1">Checking</option>
								<option value="2">Credit</option>
								<option value="3">Cash</option>
								<option value="4">Gift Card</option>
								<option value="5" disabled>Savings (off-budget)</option>
								<option value="6" disabled>Debt (off-budget)</option>
							</select>
						</div>
						<div className="mb-3">
							<label className="col-form-label">Initial Balance:</label>
							<br />For credit cards, use a negative balance to indicate what you owe. E.g. -1234.56
							<input type="number" className="form-control" id="accountBalance"
								onBlur={e => { setInitialBalance(e.target.value); }}
							/>
						</div>

					</Modal.Body>
					<Modal.Footer>
						<div
							className={`button button-darkGreen ${(initialBalance && initialName) ? '' : 'disabled'}`}
							onClick={() => {
								if (initialBalance && initialName) {
									addNewAccount();
								} else {
									console.log("Can't open account without initial balance.");
								}
							}}
						>
							Save
						</div>
						<div
							className='button button-lightGreen'
							onClick={handleClose}>
							Cancel
						</div>
					</Modal.Footer>
				</Modal>
			</>
		);
	}


	const [accountsLoaded, setAccountsLoaded] = useState(false);

	// Listen for authentication state changes
	useEffect(() => {
		const unsubscribe = firebaseAuth.onAuthStateChanged(function (user) {
			if (user) {
				setUserID(firebaseAuth.currentUser.uid);
				setEmail(encodeURIComponent(firebaseAuth.currentUser.email));
				setIdToken('');
				setIsLoading(true); // Set isLoading to true initially

				user.getIdToken()
					.then(idToken => {
						setIdToken(idToken);
						setAccountsLoaded(true); // Update the flag to indicate authentication state is verified
					});
			} else {
				props.history.push('/login');
			}
		});

		// Cleanup function to unsubscribe the listener
		return () => unsubscribe();
	}, [props.history]);

	// Fetch accounts once authentication state is confirmed
	useEffect(() => {
		if (accountsLoaded && idToken && isLoading) {
			api_get_accounts(idToken).then((data) => {
				const newData = data;
				console.log(accounts, newData);
				setAccounts(newData.filter(item => item.accountid !== null));
				setDaysLeft(newData[0]['days_left']);
				setIsLoading(false);
			});
		}
	}, [accountsLoaded, idToken, isLoading]);

	useEffect(() => {
		if (userID !== '' && currEditAccountId !== '') {
			setNewAccName(accounts.filter(d => d.accountid == currEditAccountId)[0]['name']);
			setNewAccDescription(accounts.filter(d => d.accountid == currEditAccountId)[0]['description']);
			setNewAccType(accounts.filter(d => d.accountid == currEditAccountId)[0]['type']);
			// get current account details
			// getAccountDetails();

		} else if (currEditAccountId === '') {
			setNewAccName('');
			setNewAccDescription('');
			setNewAccType('');
		}
	}, [currEditAccountId]);

	useEffect(() => {
		// This is where we allow the modal to be shown
		if (!isLoading && accounts.length >= 0 && accounts.length !== undefined) {
			console.log(accounts.length, days_left);
			setShowNeedAccount(accounts.length <= 0);
		}
	}, [!isLoading]);
	//   something is being set AFTER setShowNeedAccount

	useEffect(() => {
		console.log('accounts changed!');
	}, [accounts]);


	// const [currEditAccount, setCurrEditAccount] = useState('');
	// const [currEditAccountId, setCurrEditAccountId] = useState('');
	// const [newAccName, setNewAccName] = useState('');
	// const [newAccType, setNewAccType] = useState('');
	// const [newAccDescription, setNewAccDescription] = useState('');
	// const [idToken, setIdToken] = useState('');
	// const [confirmed, setConfirmed] = useState(false);
	// const [showSettings, setShowSettings] = useState(false);
	// const [showEdit, setShowEdit] = useState(false);
	// const [showHide, setShowHide] = useState(false);
	// const [showDelete, setShowDelete] = useState(false);
	// const [hidden, setHidden] = useState(false);
	// let [days_left, setDaysLeft] = useState(600);
	// const [showNeedAccount, setShowNeedAccount] = useState(false);
	// const [isLoading, setIsLoading] = useState(true);

	const toCurrency = (x) => {
		// *** Convert to format $0.00
		return (x / 100).toLocaleString('US', {
			style: "currency",
			currency: "USD"
		});
	}


	const toNumber = (x) => {
		const newX = 100 * Number(x.replace(/[^0-9.-]/g, '')
			.replace(/(\..*)\./g, '$1')
			.replace(/(?!^)-/g, '')
			.replace(/^0+(\d)/gm, '$1'));
		// console.log('Turning into Number...' + newX);
		return (newX);

	}

	const api_close_account = () => {
		// console.log("About to make the API reuqest...POST");
		// toHide = 1 to hide the account
		// toHide = 0 to unhide the account
		// if the acc is currently closed, pass 0 to unhide. Otherwise pass 1.
		var closed = accounts.filter(d => d.accountid == currEditAccountId)[0]['closed_at'];
		var balance = accounts.filter(d => d.accountid == currEditAccountId)[0]['running_balance'];
		var toHide = 1;

		// If the account is currently closed, 
		// we need to reopen the account.
		if (closed != null) {
			toHide = 0;
			balance = toNumber(document.getElementById('newAccountBalance').value);
		}
		const data = {
			token: idToken,
			accountid: currEditAccountId,
			toHide: toHide,
			balance: balance
		};
		const requestOptions = {
			method: 'POST',
			// headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({})
		};

		// console.log('Adding before api post...');
		// Where we're fetching data from
		const baseURL = dbURL + 'closeaccount?';
		const reqURL = baseURL
			+ 'token=' + data.token
			+ '&accountid=' + data.accountid
			+ '&tohide=' + data.toHide
			+ '&balance=' + data.balance;
		console.log(reqURL);
		// return (1);
		return fetch(reqURL, requestOptions)
			// We get the API response and receive data in JSON format
			.then((response) => response.json())
			// .then((data) => console.log("Adding after api post.." + data))
			.catch((error) => console.error(error));
	}

	const api_delete_account = () => {
		// console.log("About to make the API reuqest...POST");
		const data = {
			token: idToken,
			accountid: currEditAccountId
		};
		const requestOptions = {
			method: 'DELETE'
			// headers: { 'Content-Type': 'application/json' },
			// body: JSON.stringify({})
		};

		// console.log('Adding before api post...');
		// Where we're fetching data from
		const baseURL = dbURL + 'deleteaccount/';
		const reqURL = baseURL
			+ '' + data.accountid
			+ '?token=' + data.token;
		// + '&transactionid=' + data.transactionid;
		console.log(reqURL);
		return fetch(reqURL, requestOptions)
			// We get the API response and receive data in JSON format
			.then((response) => response.json())
			// .then((data) => console.log("Adding after api post.." + data))
			.catch((error) => console.error(error));
	}

	function attemptCloseAcc() {
		// console.log("Attempting to close/reopen now");
		// if (newType == 2) {
		api_close_account();
		setTimeout(() => {
			// console.log("Toggling the other value...");
			setConfirmed(!confirmed);
		}, 250);
		return (1);
	}


	function attemptDeleteAcc() {
		// console.log("Attempting to close/reopen now");
		// if (newType == 2) {
		api_delete_account();
		setTimeout(() => {
			// console.log("Toggling the other value...");
			setConfirmed(!confirmed);
		}, 250);
		return (1);
	}


	function HideConfirmation(props) {
		const [rerender, setRerender] = useState(false);
		const [allowDelete, setAllowDelete] = useState(false);
		const [newBalance, setNewBalance] = useState(null);
		var running_balance = accounts.filter(d => d.accountid == currEditAccountId)[0]['running_balance'];
		var t_count = accounts.filter(d => d.accountid == currEditAccountId)[0]['t_count'];
		var closed_at = accounts.filter(d => d.accountid == currEditAccountId)[0]['closed_at']
		// console.log("Props for hiding", props);

		var hideWord = 'Close';
		if (closed_at != null) {
			hideWord = 'Reopen';
		}

		const handleClose = () => {
			setShowHide(false);
			setAllowDelete(false);
		}
		const handleShow = () => { setShowHide(true); }

		return (
			<>
				{showEdit || showDelete ? (
					<></>
				) : (
					<>
						{showHide ? (
							<Modal show={showHide} onHide={handleClose}>
								<Modal.Header closeButton>
									<Modal.Title>
										Confirm {hideWord}
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>

									{closed_at != null ? (
										<div>
											You'll need to provide a new initial balance for the account. If the balance is positive, the money will be added to your budget.
											<br /><br />
											For credit cards, use a negative balance to indicate what you owe. E.g. -1234.56
											<br /><br />
											<input type="number" className="form-control" id="newAccountBalance"
												defaultValue={newBalance}
												onChange={e => { setNewBalance(e.target.value) }}
											/>
										</div>
									) : (
										<div>
											Are you sure you want to {hideWord.toLowerCase()} this account?
											<br /> <br />
											<div className="container">
												<div className="row mb-3">
													<div className="col-1" id="confirmToggleDiv">
														<Toggle
															id='confirmToggle'
															defaultChecked={allowDelete}
															icons={false}
															onChange={() => {
																setAllowDelete(!allowDelete);
																setRerender(!rerender);
															}}
														/>
													</div>
													<div className="col">
														<div className="confirmToggleLabel">I understand that:</div>
													</div>
												</div>
											</div>
											<ul>
												<li>No new transactions can be recorded for {currEditAccount}.</li>
												<li>{currEditAccount} will not appear on the History page.</li>
												{running_balance <= 0 || running_balance == null ? (
													<div></div>
												) : (
													<li><b>The remaining {toCurrency(running_balance)} will be removed from my budget.</b></li>
												)}

											</ul>

											{t_count == 0 || t_count == null ? (
												<>
													<div className='mt-3'>
														Since there are no transactions associated with this account,
														you may wish to delete it instead. Deletion is permanent and can't be undone.
													</div>

												</>
											) : (
												<div></div>
											)}
										</div>
									)}


								</Modal.Body>
								<Modal.Footer>
									<div className={`button button-darkRed ${!allowDelete ? 'disabled' : ''}`}
										onClick={() => {
											if (allowDelete) {
												attemptCloseAcc();
											} else {
												console.log("Can't close without confirmation.");
											}
										}}
										disabled={!allowDelete}>
										Yes, {hideWord}
									</div>
									<div className='button button-lightRed' onClick={handleClose}>
										Cancel
									</div>
								</Modal.Footer>
							</Modal>
						) : (
							<div className='text-center mt-5 fs-5 fw-bolder text-black-75 row hoverPointer'
								onClick={handleShow}>
								<div className='col-1'></div>
								<div className='col-1'>
									{hidden ? (
										<FontAwesomeIcon icon={faEye} className="me-3" />
									) : (
										<FontAwesomeIcon icon={faEyeSlash} className="me-3" />
									)}
								</div>
								<div className='col-8'>{hideWord} Account</div>
								<div className='col-1'></div>
							</div>
						)}
					</>
				)
				}

			</>
		);
	}

	function DeleteConfirmation() {
		// const [showDelete, setShowDelete] = useState(false);
		const [allowDelete, setAllowDelete] = useState(false);
		// const [newBalance, setNewBalance] = useState(null);
		var running_balance = accounts.filter(d => d.accountid == currEditAccountId)[0]['running_balance'];
		var t_count = accounts.filter(d => d.accountid == currEditAccountId)[0]['t_count'];
		var closed_at = accounts.filter(d => d.accountid == currEditAccountId)[0]['closed_at']
		var t_sum = accounts.filter(d => d.accountid == currEditAccountId)[0]['t_sum']
		var t_months = accounts.filter(d => d.accountid == currEditAccountId)[0]['t_months']
		var t_budgeted = accounts.filter(d => d.accountid == currEditAccountId)[0]['t_budgeted']
		// var allowDelete = false;
		// console.log("props for", showEdit, showHide, showDelete, allowDelete);

		var hideWord = 'Delete';

		// if (newBalance != null) {
		// 	setAllowDelete(true);
		// 	// allowDelete = true;
		// }


		const handleClose = () => {
			setShowDelete(false);
			setAllowDelete(false);
			// allowDelete = true;
		}
		const handleShow = () => setShowDelete(true);

		return (
			<> {showHide || showEdit ? (
				<></>
			) : (
				<>
					{showDelete ? (
						<>
							<div className='text-center mt-5 fs-5 fw-bolder text-black-50'
								onClick={handleShow}>
								{hideWord} Account
							</div>

							<Modal show={showDelete} onHide={handleClose}>
								<Modal.Header closeButton>
									<Modal.Title>
										Confirm {hideWord}
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>


									<div>
										Are you sure you want to {hideWord.toLowerCase()} this account?
										<br /> <br />
										<div className="container">
											<div className="row mb-3">
												<div className="col-1" id="confirmToggleDiv">
													<Toggle
														id='confirmToggle'
														defaultChecked={allowDelete}
														icons={false}
														onChange={() => {
															setAllowDelete(!allowDelete)
															// allowDelete = !allowDelete;
														}}
													/>
												</div>
												<div className="col">
													<div className="confirmToggleLabel">I understand that:</div>
												</div>
											</div>
										</div>

										<ul>
											{(t_count == 0 || t_count == null) && (closed_at != null) ? (
												<div></div>
											) : (
												<>
													<li>{t_count}
														{t_count == 1 ? (' transaction') : (' transactions')} totalling {toCurrency(t_sum)} will be permanently deleted.</li>

													{/* If closed, use t_sum. If active, use t_budgeted/t_income. */}
													{closed_at != null ? (
														<li>
															{toCurrency(t_sum)} used over {t_months}
															{t_months == 1 ? (' month ') : (' months ')}
															will be removed from the budget. <b>These months may now appear overbudget.</b>
														</li>
													) : (
														<li>
															{toCurrency(t_budgeted)} of available money over {t_months}
															{t_months == 1 ? (' month ') : (' months ')}
															will be removed from the budget. <b>These months may now appear overbudget.</b>
														</li>

													)}
												</>
											)}
											<li>
												Deletion is permanent and can't be undone.
											</li>
										</ul>
									</div>


								</Modal.Body>
								<Modal.Footer>
									<div id='btn-delete-acc'
										className={`button button-darkRed ${!allowDelete ? 'disabled' : ''}`}
										onClick={() => {
											if (allowDelete) {
												attemptDeleteAcc();
											} else {
												console.log("Can't delete without confirmation.");
											}
										}}
										disabled={!allowDelete} >
										Yes, {hideWord}
									</div>
									<div className='button button-lightRed'
										onClick={handleClose}>
										Cancel
									</div>
								</Modal.Footer>
							</Modal>
						</>
					) : (

						<div className='text-center mt-5 fs-5 fw-bolder text-black-75 row hoverPointer'
							onClick={handleShow}>
							<div className='col-1'></div>
							<div className='col-1'><FontAwesomeIcon icon={faXmarkCircle} className="me-3" /></div>
							<div className='col-8'>Delete Category</div>
							<div className='col-1'></div>
						</div>
					)}


				</>
			)}

			</>
		);
	}


	function DeleteConfirmation3(props) {
		const [show, setShow] = useState(false);
		const [allowDelete, setAllowDelete] = useState(false);
		const [newBalance, setNewBalance] = useState(null);
		var initial_balance = props['initial_balance'];
		var running_balance = props['running_balance'];

		var hideWord = 'Delete';

		if (newBalance != null) {
			setAllowDelete(true);
		}

		const handleClose = () => {
			setShow(false);
			setAllowDelete(false);
		}
		const handleShow = () => setShow(true);


		return (
			<>
				<div className='text-center mt-5 fs-5 fw-bolder text-black-50'
					onClick={handleShow}>
					{hideWord} Account
				</div>

				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>
							Confirm {hideWord}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>


						<div>
							Are you sure you want to {hideWord.toLowerCase()} this account?
							<br /> <br />
							<div className="container">
								<div className="row mb-3">
									<div className="col-1" id="confirmToggleDiv">
										<Toggle
											id='confirmToggle'
											defaultChecked={allowDelete}
											icons={false}
											onChange={() => {
												setAllowDelete(!allowDelete)
											}}
										/>
									</div>
									<div className="col">
										<div className="confirmToggleLabel">I understand that:</div>
									</div>
								</div>
							</div>

							<ul>
								{(props['t_count'] == 0 || props['t_count'] == null) && (props['closed_at'] != null) ? (
									<div></div>
								) : (
									<>
										<li>{props['t_count']}
											{props['t_count'] == 1 ? (' transaction') : (' transactions')} totalling {toCurrency(props['t_sum'])} will be permanently deleted.</li>

										{/* If closed, use t_sum. If active, use t_budgeted/t_income. */}
										{props['closed_at'] != null ? (
											<li>
												{toCurrency(props['t_sum'])} used over {props['t_months']}
												{props['t_months'] == 1 ? (' month ') : (' months ')}
												will be removed from the budget. <b>These months may now appear overbudget.</b>
											</li>
										) : (
											<li>
												{toCurrency(props['t_budgeted'])} of available money over {props['t_months']}
												{props['t_months'] == 1 ? (' month ') : (' months ')}
												will be removed from the budget. <b>These months may now appear overbudget.</b>
											</li>

										)}
									</>
								)}
								<li>
									Deletion is permanent and can't be undone.
								</li>
							</ul>
						</div>


					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger"
							onClick={() => attemptDeleteAcc()}
							disabled={!allowDelete} >
							Yes, {hideWord}
						</Button>
						<Button variant="outline-danger" onClick={handleClose}>
							Cancel
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	class AccountListScreen extends Component {
		render() {
			return (
				<>
					{/* Desktop */}

					<div className='d-none d-lg-block greyBG'>
						<div className='contentSpace ms-5'>
							<div className='row ms-2'>
								<div className='col-10 mt-5 text-black-50 fw-bold'>
									<FontAwesomeIcon icon={faArrowLeft}
										className="me-3 hoverPointer" />
									<span className='hoverPointer'
										onClick={() => navigate(-1)}>
										Cancel
									</span>
								</div>
							</div>
						</div>

						<div className='contentSpace ms-5 mt-5'>
							{accounts != undefined ? (
								<>
									<h1>Active Accounts</h1>
									{accounts.map((acc, index) => {
										return (
											acc.closed_at == null ? (
												<Card
													key={'acc' + acc.accountid}
													closed_at={acc.closed_at} // NOTE: itemId is required for track items
													name={acc.name}
													accountid={acc.accountid}
													setCurrEditAccount={setCurrEditAccount}
													setCurrEditAccountId={setCurrEditAccountId}
												/>
											) : (
												<div key={'empty1' + acc.accountid} style={{ display: "inline" }}></div>
											)
										);
									})}
								</>
							) : (
								<>no accounts</>
							)}

							{accounts.filter(d => d.closed_at != null).length == 0 ? (
								<div></div>
							) : (
								<>
									<h1>Closed Accounts</h1>
									{accounts.map((acc, index) => {
										return (
											acc.closed_at != null ? (
												<Card
													key={'acc' + acc.accountid}
													closed_at={acc.closed_at} // NOTE: itemId is required for track items
													name={acc.name}
													accountid={acc.accountid}
													setCurrEditAccount={setCurrEditAccount}
													setCurrEditAccountId={setCurrEditAccountId}
												/>

											) : (
												<div key={'empty2' + acc.accountid} style={{ display: "inline" }}></div>
											)
										);
									})}
								</>
							)}

							<div className='ps-3 mt-5'><AddAccountButton /></div>
						</div>

					</div>

					{/* Mobile */}
					<div className="mt-5 mb-5 pb-5 ps-3 d-block d-lg-none ">
						{accounts != undefined ? (
							<>
								<h1 className='ps-3'>Active Accounts</h1>
								{accounts.map((acc, index) => {
									return (
										acc.closed_at == null ? (
											<Card
												key={'acc' + acc.accountid}
												closed_at={acc.closed_at} // NOTE: itemId is required for track items
												name={acc.name}
												accountid={acc.accountid}
												setCurrEditAccount={setCurrEditAccount}
												setCurrEditAccountId={setCurrEditAccountId}
											/>
										) : (
											<div key={'empty3' + acc.accountid} style={{ display: "inline" }}></div>
										)
									);
								})}
								{accounts.filter(d => d.closed_at != null).length == 0 ? (
									<div></div>
								) : (
									<>
										<h1 className='ps-3'>Closed Accounts</h1>
										{accounts.map((acc, index) => {

											return (
												acc.closed_at != null ? (
													<Card
														key={'acc' + acc.accountid}
														closed_at={acc.closed_at} // NOTE: itemId is required for track items
														name={acc.name}
														accountid={acc.accountid}
														setCurrEditAccount={setCurrEditAccount}
														setCurrEditAccountId={setCurrEditAccountId}
													/>

												) : (
													<div key={'empty4' + acc.accountid} style={{ display: "inline" }}></div>
												)
											);
										})}
									</>
								)}
							</>
						) : (
							<div></div>
						)}
						<div className='ps-3 mt-5'><AddAccountButton /></div>

					</div>
				</>
			)
		}
	}

	function NeedToAddAccountModal() {
		const [show, setShow] = useState(true);
		const [initialBalance, setInitialBalance] = useState(null);
		const [initialName, setInitialName] = useState(null);

		const handleClose = () => {
			setShow(false);
			setInitialBalance(null);
			setShowNeedAccount(false);
		}
		const handleShow = () => setShow(true);

		return (
			<>
				<Modal show={show} >
					<Modal.Header>
						<Modal.Title>Add Your First Account</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="col">
							<div className="">Envelope or zero-sum budgeting means budgeting with only the money you currently have. To keep things simple yet mindful, we recommend manually tracking your spending through your primary checking account. </div>
							<br />
							<div>Go ahead and add your account with its current balance. Then you can start telling your money where it needs to go.</div>
							<br />

						</div>
						<fieldset>
							<div className="mb-3 flatInputField">
								<input type="text" className="form-control"
									id="accountName"

									placeholder='Account Name E.g. First National Checking'
									onBlur={e => { setInitialName(e.target.value); }}
								// onBlur={e => {
								// updateAmt(e.target.value);}}
								/>
							</div>
							<div className="mb-3 flatInputField">
								<input
									type="text"
									className="form-control"
									placeholder='Description E.g. My favorite bank!'
									id="accountDescription" />
							</div>
							<div className="mb-3 flatInputField">
								<select name="type" id="accountType"
									className="form-control" >
									<option value="1">Checking</option>
									<option value="2">Credit</option>
									<option value="3">Cash</option>
									<option value="4">Gift Card</option>
									<option value="5" disabled>Savings (off-budget)</option>
									<option value="6" disabled>Debt (off-budget)</option>
								</select>
							</div>
							<div className="mb-3">
								<br />For credit cards, use a negative balance to indicate what you owe. E.g. -1234.56
								<div className='flatInputField'>
									<input
										type="number"
										className="form-control "
										id="accountBalance"
										placeholder='Initial Balance E.g. 1736.44'
										onBlur={e => { setInitialBalance(e.target.value); }}
									/>
								</div>
							</div>
						</fieldset>
					</Modal.Body>
					<Modal.Footer>
						<div
							className={`button button-darkGreen ${(initialBalance && initialName) ? '' : 'disabled'}`}
							onClick={() => {
								if (initialBalance && initialName) {
									handleClose();
									addNewAccount();
								} else {
									console.log("Can't open account without initial balance.");
								}
							}}
						>
							Save
						</div>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	function EditAccName() {
		// const [show, setShow] = useState(false);
		const [editName, setEditName] = useState(accounts.filter(d => d.accountid == currEditAccountId)[0]['name']);
		const [editType, setEditType] = useState(accounts.filter(d => d.accountid == currEditAccountId)[0]['type']);
		const [editDescription, setEditDescription] = useState(accounts.filter(d => d.accountid == currEditAccountId)[0]['description']);

		const handleClose = () => setShowEdit(false);
		const handleShow = () => setShowEdit(true);

		return (
			<>
				{showHide || showDelete ? (
					<></>
				) : (
					<>
						{showEdit ? (
							<Modal show={showEdit} onHide={handleClose}>
								<Modal.Header closeButton>
									<Modal.Title>Edit Account</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div className="mb-3 mt-3">
										<input type="text" className="form-control"
											id="newAccNameInput"
											placeholder='Account Name'
											defaultValue={newAccName === '' ? (accounts.filter(d => d.accountid == currEditAccountId)[0]['name']) : (newAccName)}
											onBlur={e => {
												// setNewAccName(e.target.value);
												setEditName(e.target.value);
											}}
										/>
									</div>
									<div className="mb-3">
										<div className="mb-3">
											<select name="category-section"
												className="form-control"
												id="newAccTypeInput"
												defaultValue={accounts.filter(d => d.accountid == currEditAccountId)[0]['type']}
												onBlur={e => {
													// setNewAccType(e.target.value);
													setEditType(e.target.value);
												}}
											>
												<option value=''>--select a section--</option>
												<option value="1">Checking</option>
												<option value="2">Credit</option>
												<option value="3">Cash</option>
												<option value="4">Gift Card</option>
												<option value="5">Savings (off-budget)</option>
												<option value="6">Debt (off-budget)</option>
											</select>
										</div>
									</div>

									<div className="mb-3 mt-3">
										<input type="text" className="form-control"
											id="newAccDescriptionInput"
											placeholder='Account Description'
											defaultValue={newAccDescription === '' ? (accounts.filter(d => d.accountid == currEditAccountId)[0]['description']) : (newAccDescription)}
											onBlur={e => {
												// setNewAccDescription(e.target.value);
												setEditDescription(e.target.value);
											}}
										/>
									</div>
								</Modal.Body>
								<Modal.Footer>
									<div className='button button-darkGreen'
										onClick={() => {
											attemptUpdateAcc(editName, editType, editDescription);
											handleClose();
										}}
									>
										Save
									</div>
									<div className="button button-lightGreen" onClick={handleClose}>
										Cancel
									</div>
								</Modal.Footer>
							</Modal>

						) : (
							<div className='text-center mt-5 fs-5 fw-bolder text-black-75 row hoverPointer'
								onClick={handleShow}>
								<div className='col-1'></div>
								<div className='col-1'><FontAwesomeIcon icon={faPencil} className="me-3" /></div>
								<div className='col-8'>Edit Account</div>
								<div className='col-1'></div>
							</div>

						)}


					</>
				)}

			</>
		);
	}

	function SettingsModal() {
		// const [showMenu, setShowMenu] = useState(true);

		const handleCloseMenu = () => {
			// console.log("closing the setings menu")
			// maybe sleep here for 0.1s for effect
			setShowSettings(false)
		};
		const handleShowMenu = () => setShowSettings(true);


		return (
			<>
				{days_left === '-1' ? (
					<></>
				) : (
					<>
						<div className='gearIcon hoverPointer'>
							<FontAwesomeIcon icon={faCog}
								onClick={handleShowMenu} />
						</div>
					</>
				)}

				{(!showSettings && (showEdit || showHide || showDelete)) ? (
					<div>
						<EditAccName />
						<HideConfirmation />
						<DeleteConfirmation />
					</div>
				) : (
					<></>
				)}

				<Modal show={showSettings} onHide={handleCloseMenu}
					aria-labelledby="contained-modal-title-vcenter"
					dialogClassName="bottom-modal-dialog">
					{/* <Modal.Header closeButton>
                        <Modal.Title>Confirm {hideWord}</Modal.Title>
                    </Modal.Header> */}
					<Modal.Body>
						<div className='bottomModal'>
							<div onClick={() => {
								setShowSettings(false);
							}}>
								<EditAccName />
								<hr className='m-5' />
							</div>
							<div onClick={() => {
								setShowSettings(false);
							}}>
								<HideConfirmation
									closed_at={accounts.filter(d => d.accountid == currEditAccountId)[0]['closed_at']}
									t_count={accounts.filter(d => d.accountid == currEditAccountId)[0]['t_count']}
									initial_balance={accounts.filter(d => d.accountid == currEditAccountId)[0]['initial_balance']}
									running_balance={accounts.filter(d => d.accountid == currEditAccountId)[0]['running_balance']}
								/>
								<hr className='m-5' />
							</div>
							<div onClick={() => {
								setShowSettings(false);
							}}>
								<DeleteConfirmation />
							</div>
						</div>
					</Modal.Body>
					{/* <Modal.Footer>
                        <Button variant="danger" onClick={() => attemptHide(catId)}>
                            Yes, {hideWord}
                        </Button>
                        <Button variant="outline-danger" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer> */}
				</Modal>
			</>
		);
	}


	function CurrentBalance() {
		return (
			<>
				<div className='catSummaryContainer ms-3 me-3'>
					<div className='row'>
						<div className='col-4'>
							<img
								src={bankcoins}
								className='img-fluid mt-2'
								alt="..."></img>
						</div>
						<div className='col-8'>
							<div className='text-center alertDisplay h1 mt-3 mb-0 fw-bold'>
								{toCurrency(accounts.filter(d => d.accountid == currEditAccountId)[0]['running_balance'])}
							</div>
							<div className='text-center fw-bold text-black-50'>
								<small>Current Balance</small>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}


	function InitialBalance() {
		return (
			<>
				<div className='budgetAlert mt-4 mb-4'>
					<div className="alert alert-warning" role="alert">
						<div className='row mb-3'>
							<div className='col'>
								<div className='alertSubheader'>Created On</div>
								<div className='fw-bold p-0' style={{ fontSize: "18px" }}>{reformatDate(accounts.filter(d => d.accountid == currEditAccountId)[0]['created_at'])}</div>
							</div>
							<div className='col-2'>
								<FontAwesomeIcon icon={faSeedling}
									className="display-4 ms-1 mt-2 hoverPointer sprout-color " />
								{/* <img
                                    src={dollarbills}
                                    className='dollarbillimg' /> */}
							</div>
							<div className='col'>
								<div className='fw-bold h5 mb-0'>{toCurrency(accounts.filter(d => d.accountid == currEditAccountId)[0]['initial_balance'])}</div>
								<div className='alertSubheader'>Initial Balance</div>
							</div>
						</div>
						{/* <div className="alert-subheading text-center">
                            See all {catName} transactions <FontAwesomeIcon
                                icon={faArrowRight}
                                className="ms-2" />
                        </div> */}
					</div>
				</div>
			</>
		)
	}

	function AccountSpendSummary() {
		return (
			<>
				<div className='catSummaryContainer ms-3 me-3'>
					<div className='h5 text-center fw-bold mb-4'>
						In & Out Flow
					</div>
					<div className='row mb-1'>
						<div className='col-2 sideways-label text-right'
							style={{ fontSize: "12px", display: "flex", alignItems: "flex-end" }}>
							Incoming
						</div>
						<div className='col-5 text-center fw-bold h6 p-3 mb-0'
							style={{ backgroundColor: "#C9F2E8", borderRight: "4px solid white" }}>
							{toCurrency(accounts.filter(d => d.accountid == currEditAccountId)[0]['t_earned'] / accounts.filter(d => d.accountid == currEditAccountId)[0]['t_months'])}
						</div>
						<div className='col-5 text-center fw-bold h6 p-3 mb-0 text-white'
							style={{ backgroundColor: "#82D8C2" }}>
							{toCurrency(accounts.filter(d => d.accountid == currEditAccountId)[0]['t_earned'])}
						</div>
					</div>
					<div className='row mt-1'>
						<div className='col-2 sideways-label '
							style={{ fontSize: "12px", display: "flex", alignItems: "flex-end" }}>
							Outgoing
						</div>
						<div className='col-5 text-center fw-bold h6 p-3 mb-0'
							style={{ backgroundColor: "#FFDDD6", borderRight: "4px solid white" }}>
							{toCurrency(accounts.filter(d => d.accountid == currEditAccountId)[0]['t_spent'] / accounts.filter(d => d.accountid == currEditAccountId)[0]['t_months'])}
						</div>
						<div className='col-5 text-center fw-bold h6 p-3 mb-0 text-white'
							style={{ backgroundColor: "#FF8B72" }}>
							{toCurrency(accounts.filter(d => d.accountid == currEditAccountId)[0]['t_spent'])}
						</div>
					</div>
					<div className='row'>
						<div className='col-2'>

						</div>
						<div className='col-5 text-center small pt-2'>
							Avg Monthly
						</div>
						<div className='col-5 text-center small pt-2'>
							Total
						</div>
					</div>
				</div>
			</>
		)
	}

	class SelectedAccountScreen extends Component {
		render() {
			// console.log("accounts list,", this.props);
			let accounts = this.props.accounts;
			return (
				<>
					{/* Desktop */}
					<div className='d-none d-lg-block greyBG'>
						{accounts != [] && currEditAccountId != '' ? (
							<>
								<div className='contentSpace ms-5'>
									<div className='row ms-2'>
										<div className='col-10 mt-5 text-black-50 fw-bold'>
											<FontAwesomeIcon icon={faArrowLeft}
												className="me-3 hoverPointer" />
											<span className='hoverPointer'
												onClick={() => {
													setCurrEditAccountId('');
													setNewAccName('');
													setNewAccDescription('');
													setNewAccType('');
												}}>
												Back to Accounts
											</span>
										</div>
										<div className='col-1 mt-5'>
											<div>
												<SettingsModal />
											</div>
										</div>

										<div className='row ms-2'>
											<div className='col mt-5 text-dark fw-bold fs-2'>
												{accounts.filter(d => d.accountid == currEditAccountId)[0]['name']}
												<div className='mt-2 fs-6'>
													{accounts.filter(d => d.accountid == currEditAccountId)[0]['description']}
												</div>
											</div>
										</div>

										<div className='row mt-5 ms-2'>
											<div className='col-4'>
												<CurrentBalance />
											</div>
											<div className='col-5'>
												<InitialBalance />
											</div>
										</div>

										<div className='row mt-5 ms-2'>
											<div className='col-9'>
												<AccountSpendSummary />
											</div>
										</div>

										<div id='bottomSpacing'></div>
										<div id='bottomSpacing'></div>
									</div>
								</div>

							</>
						) : (
							<div></div>
						)
						}
					</div>

					{/* Mobile */}
					<div>
						{accounts != [] && currEditAccountId != '' ? (
							<>
								<div className='greyBG d-block d-lg-none '>
									<div className='greyBG'>
										<div className='bg-white navMonthDate scrollNavMonths css-box-shadow ps-4 pt-4 pt-4 pb-5 mb-4'>
											<div className='mb-5 text-black-50 fw-bold'>
												<FontAwesomeIcon icon={faArrowLeft}
													className="me-3 hoverPointer" />
												<span className='hoverPointer'
													onClick={() => {
														setCurrEditAccountId('');
														setNewAccName('');
														setNewAccDescription('');
														setNewAccType('');
													}}>
													Back to Accounts
												</span>
											</div>
											<span id="currentDay">
												{accounts.filter(d => d.accountid == currEditAccountId)[0]['name']}
											</span>
											<div className='pull-right mt-1 me-4'>
												<SettingsModal />
											</div><br />
											<span id="currentDate">
												{accounts.filter(d => d.accountid == currEditAccountId)[0]['description']}
											</span>
										</div>

										<CurrentBalance />
										<InitialBalance />
										<AccountSpendSummary />

										<div id='bottomSpacing'></div>
										<div id='bottomSpacing'></div>
									</div>
								</div>



							</>
						) : (
							<div></div>
						)
						}
					</div>
				</>
			)
		}
	}

	return (
		<>
			<FreeTrialAlert days_left={days_left} email={email} />
			{showNeedAccount && <NeedToAddAccountModal />}
			{/* <div style={days_left === '-1' || days_left < 500
				? { marginTop: '80px' }
				: {}}> */}
				<Navigation key="nav-bar-key" />
				{confirmed ? (
					<ConfirmScreen
						buttonList={[['Back to Accounts', ''], ['Go to Budget', '/Budget']]}
					/>
				) : (
					<>
						{currEditAccountId == '' ? (
							<AccountListScreen
								accounts={accounts} />
						) : (
							<SelectedAccountScreen
								accounts={accounts}
								currEditAccountId={currEditAccountId}
							/>
						)}
					</>
				)}
				

			<div id='bottomSpacing'></div>
			<div id='bottomSpacing'></div>
		</>
	);
}
